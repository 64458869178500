export default {
    noFound: {
        clickBack: "Back",
        noFoundPage: "Page not found",
    },
    global: {
        title: "Smart Meeting Management Platform",
        gotoLogin: "Back to Login",
        verifyIdentity: "Verify Identity",
        resetPassword: "Reset Password",
        finish: "Finish",
        footer: "All rights reserved © Guangdong Baolun Electronics Co., Ltd.",
        userAgree: "《Service agreement》",
        and: " and ",
        privacyPolicy: "《Privacy Policy》",
        codeLogin: "Verification code login",
        passwordLogin: "Password login",

        haveRead: "I have read and agreed",
        queryRead: "Please confirm that I have read and agree",
        meetingNo: "Meeting NO",
        meetingTime: "Meeting Time",
        inviteYouTo: "Invite you to",
        to: "Enter",
    },
    axios: {
        expire: "User ID has expired, please contact the administrator",
        noRegister: "Your phone number is not registered, go to the registration page now",
        pleaseLogin: "Login expired, please log in agin",
    },
    route: {
        home: "Home",
        userManage: "User Management",
        meetingManage: "Meeting Management",
        myMeeting: "My Meeting",
        createMeeting: "Create Meeting",
        editMeeting: "Edit Meeting",
        unitMeeting: "Organization Meeting",
        historyMeeting: "Historical Meeting",
        meetingRecord: "Meeting Recording",
        meetingSetting: "Meeting Setings",
        unitManage: "Organization Management",
        orgStr: "Organizational Structure",
        unitInfo: "Organization Info",
        disUnit: "Disband Organization",
        meetingRoom: "Meeting Room Management",
        flatPanel: "Equipment management",

        persionalInfo: "Personal Info", //23
        logout: "Log out",
        exitUnit: "Exit Organization",
        disUser: "Deactivated User",
        meetingMaterials: "Meeting Materals",
        voteScore: "Voting and Scoring",
        personalManage: "Personnel Management",
        signManage: "Sign-in Management", //30

        looseUser: "Loose User",
        createUnit: "Create Organization",
        createSucess: "Created successfully",
        waitReview: "Waiting for Review",
        reviewRejected: "Review Rejected",
        interface: "Interface Statistics",
        versionManage: "Version Management", // 41
        advanced: "Advanced",

        modify: "Modify", //42
        add: "Add",
        meeting: "Meeting",
        myRecord: "My Recording",
    },
    message: {
        message1: "Name is required",
        message2: "Name cannot contain spaces",
        message3: "Password is required",
        message4: "Password cannot contain spaces", // 48
        message5: "The password is limited to 8~16 digits, consisting of 3 or more than 3 numbers, uppercase letters, lowercase letters, and special symbols.",
        message6: "Password is limited to 8~16 digits",
        message7: "Name is required",
        message8: "Name cannot contain special characters",
        message9: "Name length cannot be longer than",
        message10: "Phone number cannot be empty",
        message11: "Please enter the correct phone number",
        message12: "Verification code must be filled",
        message13: "Verification code cannot contain spaces",
        message14: "Verification code length is limited to 6",
        message15: "Email address can not be empty",
        message16: "Please enter the correct Email address",
        message17: "Address length cannot be greater than",
        message18: "Phone number cannot contain non-numeric content",
        message19: "The phone number format is wrong",
        message20: "Position length cannot be longer than 20",
        message21: "Only letters, numbers, underscores and Chinese characters are allowed",
        message22: "You have joined the organization",
        message23: "The verification code has been sent to your mobile phone",
        message24: "Your application has not been approved. To reapply, please click",
        message25: "Please fill in the username and login password",
        message26: "Please fill in your name",
        message27: "Please check whether the information is entered correctly",
        message28: "The two entered passwords do not match",

        tip1: "Unregistered phone number will be automatically registered after verification",
        tip2: "Please enter your phone number",
        tip3: "Please enter the code",
        tip4: "Please enter the password",
        tip5: "For details, please contact the administrator",
        tip6: "No meeting name",
    },
    clickEvent: {
        login1: "Log in now",
        register: "Register new user",
        forgot: "Forgot Password",
        getCode: "Send code",
        reSend: "Resend",
        reApply: "Re-apply",
        cInfo: "Complete information",
        back: "Back",
        regJoin: "Register and Join",
        accountExists: "Account already exists",
        logNow: "Log in now",
    },
    label: {
        name: "Name",
        password: "Password",
        confirmPassword: "Confirm Password",
        phoneNum: "Phone number",
        phoneEmail: "Phone/Email",
        email: "Email",
        code: "Code",
    },
    all: {
        Message1: "User ID has expired, please contact the administrator",
        Message2: "Your phone number is not registered, go to the registration page now",
        Message3: "Expired",
        Message4: "Login expired, please log in again",
        Message5: "User ID has expired",
        Message6: "Home",
        Message7: "Meeting Management",
        Message8: "My Meeting",
        Message9: "Create Meeting",
        Message10: "Edit Meeting",
        Message11: "Organization Meeting",
        Message12: "Historical Meeting",
        Message13: "Meeting Recording",
        Message14: "Meeting Settings",
        Message15: "Organization Management",
        Message16: "Organizational Structure",
        Message17: "Organization Info",
        Message18: "Disband Organization",
        Message19: "Meeting Room Management",
        Message20: "Interactive Flat Panel",
        Message21: "User Management",
        Message22: "Personal Info",
        Message23: "Delete Account",
        Message24: "Exit Organization",
        Message25: "Deactivated User",
        Message26: "Meeting Materials",
        Message27: "Voting and Scoring",
        Message28: "Personnel Management",
        Message29: "Sign-in Management",
        Message30: "Loose User",
        Message31: "Create Organization",
        Message32: "Created successfully",
        Message33: "Waiting for Review",
        Message34: "Review Rejected",
        Message35: "Conference Interactive Flat Panel",
        Message36: "ID has expired, please log in again",
        Message37: "Interface Statistics",
        Message38: "Log Management",
        Message39: "",
        Message40: "Version Management",
        Message41: "Modify",
        Message42: "Add",
        Message43: "Meeting",
        Message44: "My Recording",
        Message45: "Beijing",
        Message46: "Microsoft Yahei",
        Message47: "Name is required",
        Message48: "Name cannot contain spaces",
        Message49: "Password is required",
        Message50: "Password cannot contain spaces",
        Message51: "The password is limited to {0} digits, consisting of {1} or more than {2} numbers, uppercase letters, lowercase letters, and special symbols",
        Message52: "Password is limited to {0} digits",
        Message53: "Name is required",
        Message54: "Name cannot contain special characters",
        Message55: "Name length cannot be longer than",
        Message56: "Phone number cannot be empty",
        Message57: "Please enter the correct phone number",
        Message58: "Verification code must be filled",
        Message59: "Verification code cannot contain spaces",
        Message60: "Verification code length is limited to",
        Message61: "Email address can not be empty",
        Message62: "Please enter the correct Email address",
        Message63: "Address length cannot be greater than",
        Message64: "Phone number cannot contain non-numeric content",
        Message65: "The phone number format is wrong",
        Message66: "Position length cannot be longer than",
        Message67: "Only letters, numbers, underscores and Chinese characters are allowed",
        Message68: "Failed to read user information, please log in again",
        Message69: "Page not found",
        Message70: "Back",
        Message71: "Conference Management Platform",
        Message72: "You have joined the organization",
        Message73: "Back to Login",
        Message74: "Verify Identity",
        Message75: "Reset Password",
        Message76: "Finish",
        Message77: "All rights reserved",
        Message78: "Guangdong Baolun Electronics Co., Ltd.",
        Message79: "《Service agreement》",
        Message80: " and ",
        Message81: "《Privacy Policy》",
        Message82: "Verification code login",
        Message83: "Password login",
        Message84: "Unregistered phone number will be automatically registered after verification",
        Message85: "Please enter your phone number",
        Message86: "Please enter the code",
        Message87: "I have read and agreed",
        Message88: "Login",
        Message89: "Register",
        Message90: "Forgot Password",
        Message91: "Please enter the password",
        Message92: "Send code",
        Message93: "Resend",
        Message94: "The verification code has been sent to your mobile phone",
        Message95: "Your application has not been approved",
        Message96: "To reapply, please click",
        Message97: "Re-apply",
        Message98: "For details, please contact the administrator",
        Message99: "No meeting name",
        Message100: "Meeting NO.:",
        Message101: "Meeting Time:",
        Message102: "Complete information",
        Message103: "Please fill in the username and login password",
        Message104: "Back",
        Message105: "Name",
        Message106: "Please fill in your name",
        Message107: "Password",
        Message108: "Confirm Password",
        Message109: "Register and Join",
        Message110: "Please check whether the information is entered correctly",
        Message111: "The two entered passwords do not match",
        Message112: " Invite you to",
        Message113: "join",
        Message114: "Phone number",
        Message115: "Verification code",
        Message116: "Account already exists",
        Message117: "Log in now",
        Message118: "The link has expired, you can re-copy the link to enter the invitation page",
        Message119: "You have applied to join another organization",
        Message120: "You have joined the organization and cannot join it again",
        Message121: "Invitation accepted successfully, you will  log in directly",
        Message122: "Next",
        Message123: "Tip: If your mobile phone and Email are both unavailable, please contact the enterprise admin to update your mobile phone and Email as soon as possible",
        Message124: "Only phone numbers in mainland China can be used to retrieve passwords",
        Message125: "The phone number is not registered, you can go to the registration page to register",
        Message126: "New password",
        Message127: "Please confirm your password",
        Message128: "Tip:",
        Message129: " numbers, English letters and symbols",
        Message130: "The two input passwords are inconsistent",
        Message131: "Password modified successfully",
        Message132: "",
        Message133: " seconds later",
        Message134: "New User Registration",
        Message135: "This phone number has been registered, you can log in directly",
        Message136: "Submit",
        Message137: "Congratulations, you have registered successfully",
        Message138: "Logging in automatically",
        Message139: "Search meeting name",
        Message140: "Back to Home",
        Message141: "Log out",
        Message142: "Do you want to logout?",
        Message143: "Conference Management Background",
        Message144: "Cancel",
        Message145: "OK",
        Message146: "Prompt",
        Message147: "Personal",
        Message148: "Management",
        Message149: "No relevant data",
        Message150: "",
        Message151: "Not started",
        Message152: "Ongoing",
        Message153: "Have ended",
        Message154: "Admin",
        Message155: "General user",
        Message156: "Click to upload",
        Message157: "A maximum of {0} files can be uploaded at one time; the total number of files cannot exceed {1}; the file name cannot exceed {2} characters",
        Message158: "Do you want to remove?",
        Message159: "Start date",
        Message160: "End date",
        Message161: "Please select",
        Message162: "Last week",
        Message163: "Last month",
        Message164: "Last 3 months",
        Message165: "All",
        Message166: "Upgrade Program",
        Message167: "Client Side",
        Message168: "Barrage loading",
        Message169: "Top",
        Message170: "Bottom",
        Message171: "Scroll",
        Message172: "Fill in the barrage and press Enter to send",
        Message173: "About the Author",
        Message174: "Player Feedback",
        Message175: "About",
        Message176: "Player",
        Message177: "Loop",
        Message178: "Speed",
        Message179: "Barrage Transparency",
        Message180: "Enable",
        Message181: "Please fill in the barrage content!",
        Message182: "Set the barrage color",
        Message183: "Set the barrage type",
        Message184: "Show barrage",
        Message185: "Video loading failed",
        Message186: "Barrage loading failed",
        Message187: "Failed to send barrage",
        Message188: "Switching to",
        Message189: "Switched to",
        Message190: "Picture quality",
        Message191: "Fast forward",
        Message192: "Fast rewind",
        Message193: "Massive barrage",
        Message194: "Send barrage",
        Message195: "Set",
        Message196: "",
        Message197: "Full Screen",
        Message198: "Send",
        Message199: "Screenshot",
        Message200: "",
        Message201: "",
        Message202: "Show subtitles",
        Message203: "Hide subtitles",
        Message204: "",
        Message205: "Live",
        Message206: "Video Statistics",
        Message207: "Average response time",
        Message208: "Number of calls",
        Message209: "Search",
        Message210: "Name",
        Message211: "Your account has been disabled, please contact the organization administrator.",
        Message212: "Organization resources:",
        Message213: "Usage Status:",
        Message214: "Daily Attendance",
        Message215: "Daily Meeting Duration",
        Message216: "Daily Meeting",
        Message217: "Daily Active User",
        Message218: "Last year",
        Message219: "Today's Participants",
        Message220: "meeting rooms",
        Message221: "User Account",
        Message222: "Today's Meetings",
        Message223: "Today's Meeting Duration",
        Message224: "Today's Active Users",
        Message225: "Today's Meeting Duration",
        Message226: "Today's Meetings:",
        Message227: "",
        Message228: "Today's Active Users",
        Message229: "Application to join {msg} has been completed, please wait for approval from the administrator before using.",
        Message230: "Application withdrawal",
        Message231: "Do you want to withdraw your application?",
        Message232: "Your application has been approved",
        Message233: "Your application has been rejected",
        Message234: "Review failed, please contact the administrator for details",
        Message235: "Organization name",
        Message236: "Please fill in the organization name",
        Message237: "Please enter the organization name",
        Message238: "Name length is limited to 2~20 Chinese-English",
        Message239: "Chinese-English",
        Message240: "Only Chinese or English letters are allowed",
        Message241: "Organization created successfully",
        Message242: "Invite colleagues to join",
        Message243: "Enter the organization",
        Message244: "No organization has been created yet, please create one or join one.",
        Message245: "Data loading...Please wait",
        Message246: "Please wait",
        Message247: "New node",
        Message248: "Please fill in the node name",
        Message249: "Delete node",
        Message250: "This node has users and cannot be deleted",
        Message251: "Do you want to delete the node?",
        Message252: "Edit node",
        Message253: "Node name cannot be empty",
        Message254: "Node deleted successfully",
        Message255: "Organization Disbandment Description",
        Message256: "Disbanding a organization is an irreversible operation. After disbanding the organization, all members become separate individual user accounts.",
        Message257: "Before disbanding your organization, please confirm:",
        Message258: "The files contained in your organization have been properly saved: including but not limited to meeting recordings, etc.",
        Message259: "The organization has no disputes: including complaints and reports, arbitration, litigation and so on.",
        Message260: "Phone number:",
        Message261: "Verification code:",
        Message262: "Send code",
        Message263: "The organization was successfully disbanded",
        Message264: "Reacquire",
        Message265: "Please agree to the Organization Disbandment Description first",
        Message266: "Bind Email",
        Message267: "Email:",
        Message268: "Please input your Email",
        Message269: "Code:",
        Message270: "Email bound successfully",
        Message271: "Current Email Address:",
        Message272: "This Email has been registered",
        Message273: "Confirm",
        Message274: "This phone number has been registered",
        Message275: "Modified successfully",
        Message276: "Modify Email",
        Message277: "Original Email:",
        Message278: "New Email:",
        Message279: "Tip: After changing the password, it will automatically log out",
        Message280: "Old Password",
        Message281: "Please enter the old password",
        Message282: "Please enter the new password",
        Message283: "The two new passwords entered are inconsistent",
        Message284: "Password modified successfully",
        Message285: "Modify phone number",
        Message286: "New number",
        Message287: "Please enter a new phone number",
        Message288: "Phone number modified successfully",
        Message289: "The phone number has been changed to:",
        Message290: "Organization Name:",
        Message291: "Save",
        Message292: "Address:",
        Message293: "Organization:",
        Message294: "Admin Account:",
        Message295: "Admin Password:",
        Message296: "Admin Phone Number:",
        Message297: "Bind",
        Message298: "Admin Email:",
        Message299: "Modify password",
        Message300: "Modify mobile number",
        Message301: "Bind phone number",
        Message302: "No data",
        Message303: "No address",
        Message304: "Tenant information acquisition error",
        Message305: "Organization name cannot be empty",
        Message306: "Character",
        Message307: "Only English letters and Chinese characters are allowed",
        Message308: "Address cannot be empty",
        Message309: "Address length cannot exceed",
        Message310: "Address cannot be empty!",
        Message311: "Address cannot contain spaces",
        Message312: "Account",
        Message313: "Operating Time",
        Message314: "Belonging Module",
        Message315: "Operation Type",
        Message316: "Operation Details",
        Message317: "View",
        Message318: "Module Name",
        Message319: "Log Export",
        Message320: "Delete",
        Message321: "Operation",
        Message322: "Delete Logs",
        Message323: "Do you want to delete the selected logs?",
        Message324: "Please select an item to delete",
        Message325: "Please select an item to export",
        Message326: "Log Details",
        Message327: "Operation Logs",
        Message328: "Operation Log List",
        Message329: "No Details",
        Message330: "Deleted successfully",
        Message331: "Meeting Name",
        Message332: "Booker",
        Message333: "Enterprise/Organization",
        Message334: "Organization",
        Message335: "Meeting number",
        Message336: "Start Time",
        Message337: "Record",
        Message338: "View details",
        Message339: "Download",
        Message340: "Yes",
        Message341: "No",
        Message342: "Confidential",
        Message343: "Deleting historical meetings will clear meeting related data",
        Message344: "Do you want to delete the selected historical meetings?",
        Message345: "Meeting Files",
        Message346: "File Name",
        Message347: "Download",
        Message348: "Preview",
        Message349: "Agenda Files",
        Message350: "Topic Files",
        Message351: "Temporary Files",
        Message352: "Meeting Screencast",
        Message353: "Meeting Packing Files",
        Message354: "This type is unavailable for preview",
        Message355: "Duration",
        Message356: "File Size",
        Message357: "Do you want to delete the recording files?",
        Message358: "If the file is large, it will take a long time to download. Do you want to download?",
        Message359: "No data default map",
        Message360: "Meeting Recording Files",
        Message361: "Download error",
        Message362: "Failed to delete",
        Message363: "Department",
        Message364: "Number of participants",
        Message365: "State",
        Message366: "Edit",
        Message367: "End",
        Message368: "Do you want to cancel the meeting?",
        Message369: "The current meeting is reserved by {0}",
        Message370: "Send an SMS notification to the booker",
        Message371: "Current Meeting",
        Message372: "All participants have been notified by SMS",
        Message373: "Send SMS notification to all participants",
        Message374: "A confidential meeting is in progress, do you want to forcibly end it?",
        Message375: "The current meeting is in progress, please save the meeting files on the interactive flat panel",
        Message376: "Click [OK] to forcibly end the meeting",
        Message377: "Canceled successfully",
        Message378: "Meeting stopped successfully",
        Message379: "Safe",
        Message380: "File Encrypted:",
        Message381: "Please choose whether to encrypt meeting files",
        Message382: "Encrypt (meeting files can only be opened in the terminal)",
        Message383: "Do not encrypt",
        Message384: "Note: Meeting data is encrypted and uploaded, and can only be opened by the application terminal; ordinary users can decrypt and export meeting materials in historical meetings, but cannot view details",
        Message385: "opened by the application terminal; ordinary users can decrypt and export meeting materials in historical meetings, but cannot view details",
        Message386: "Download",
        Message387: "Please choose whether to keep the meeting confidential",
        Message388: "Do not allow downloads",
        Message389: "Allow download",
        Message390: "Note: The meeting materials of historical meetings can be downloaded by ordinary users",
        Message391: "Hold time",
        Message392: "Please select the hold time",
        Message393: "Reset",
        Message394: "Permanent",
        Message395: "6 months",
        Message396: "12 months",
        Message397: "Please select a time zone",
        Message398: "Please choose whether to encrypt",
        Message399: "Please choose whether to keep it confidential",
        Message400: "Save successfully",
        Message401: "Reset successfully",
        Message402: "Uploader",
        Message403: "Upload Time",
        Message404: "Meeting agenda",
        Message405: "Object form",
        Message406: "",
        Message407: "",
        Message408: "",
        Message409: "",
        Message410: "",
        Message411: "",
        Message412: "",
        Message413: "Customize",
        Message414: "Uploadable ",
        Message415: "format documents, complex ",
        Message416: "documents need to be converted ",
        Message417: "before uploading (Maximum file size supports )",
        Message418: "Agenda file is being generated",
        Message419: "Do you want to delete the agenda file?",
        Message420: "Custom Agenda",
        Message421: "Clear",
        Message422: "Automatically generate",
        Message423: "The location of the meeting room has not yet been determined",
        Message424: "No host information",
        Message425: "Meeting Time",
        Message426: "Meeting Location",
        Message427: "Meeting Host",
        Message428: "Participants",
        Message429: "This action will overwrite the original agenda file",
        Message430: "Do you want to continue?",
        Message431: "Overwrite and Upload Canceled",
        Message432: "The meeting has ended, the file cannot be uploaded",
        Message433: "The file size cannot exceed",
        Message434: "Files of this format cannot be uploaded",
        Message435: "Uploaded successfully",
        Message436: "File processing failed",
        Message437: "Add topic",
        Message438: "Download topic documents",
        Message439: "View allowed upload formats",
        Message440: "Search topic keywords",
        Message441: "Topic Name",
        Message442: "Creation Time",
        Message443: "Topic Description",
        Message444: "Related Documents",
        Message445: "Deleting a topic will also delete related files",
        Message446: "Do you want to delete it?",
        Message447: "It will take a long time to download files that are too large in size",
        Message448: "During this period, it will not affect your other operations",
        Message449: "Do you want to download?",
        Message450: "Allowed Upload Formats",
        Message451: "Meeting Resource Preview",
        Message452: "Upload Related Files",
        Message453: "Topic name and description cannot be empty",
        Message454: "View Topic",
        Message455: "Edit Topic",
        Message456: "Deleted successfully, if the page is not updated, please refresh the page",
        Message457: "A maximum of 100 files can be uploaded at a time; the total number of files cannot exce-ed 200; the file name cannot exceed 80",
        Message458: "Tip: If there is a hyperlink in the uploaded document, the transcoding of the file will fail. Please delete the hyperlink and upload the file again",
        Message459: "The format is invalid and cannot be uploaded",
        Message460: "There are files that failed to be transcoded and will be cleared from the upload list",
        Message461: "File Name",
        Message462: "Upload Files",
        Message463: "Download Files",
        Message464: "Search file keywords",
        Message465: "Do you want to delete the selected files?",
        Message466: "Meeting Flies",
        Message467: "File larger than 2GB,Unable to upload",
        Message468: "Unable to upload",
        Message469: "Device Model",
        Message470: "Voting Title",
        Message471: "Registration Method",
        Message472: "Anonymous",
        Message473: "Real Name",
        Message474: "Voting Rules",
        Message475: "Multiple-choice",
        Message476: "Single-choice",
        Message477: "Counting Method",
        Message478: "Show of hands",
        Message479: "App Vote",
        Message480: "Add Time",
        Message481: "Scoring Title",
        Message482: "Scoring Type",
        Message483: "Device",
        Message484: "Activation code",
        Message485: "Offline",
        Message486: "Online",
        Message487: "In meeting",
        Message488: "Unbind",
        Message489: "Add",
        Message490: "Meeting room name",
        Message491: "Do you want to delete the meeting room?",
        Message492: "There is an interactive flat panel in this meeting room, please remove it before operating",
        Message493: "Add Device",
        Message494: "Select Meeting Room",
        Message495: "Please select a meeting room",
        Message496: "Edit Device",
        Message497: "Device Name",
        Message498: "Device Activation Code",
        Message499: "Do you want to unbind?",
        Message500: "Do you want to delete the selected device?",
        Message501: "Please enter the meeting room name",
        Message502: "Please enter the device name",
        Message503: "Please enter the device activation code",
        Message504: "Not activated ({msg} days remaining)",
        Message505: "The current device list has no data",
        Message506: "Device List",
        Message507: "This meeting room name already exists",
        Message508: "Device added successfully",
        Message509: "Array of occupied meeting rooms",
        Message510: "Array of all meeting rooms",
        Message511: "Unbound successfully",
        Message512: "Add activation code",
        Message513: "Export device",
        Message514: "Search name",
        Message515: "No meeting default map",
        Message516: "No meeting. To schedule a meeting, please click",
        Message517: "Do you want to cancel the meeting?",
        Message518: "All participants have been notified by SMS",
        Message519: "Participants will be notified by SMS after cancellation",
        Message520: "Do you want to end the meeting?",
        Message521: "The current meeting is in progress, please click",
        Message522: "End",
        Message523: "Cancel",
        Message524: "Time",
        Message525: "Date",
        Message526: "Password",
        Message527: "Presenter",
        Message528: "No password",
        Message529: "No meeting room allocated yet",
        Message530: "Today",
        Message531: "Last 7 days",
        Message532: "Last 30 days",
        Message533: "Score Setting",
        Message534: "Scoring Rules",
        Message535: "Take the Average",
        Message536: "Truncated Mean",
        Message537: "Click the Add Option button to manually add the required options",
        Message538: "option",
        Message539: "Add Option",
        Message540: "The maximum value is {max}; the minimum value is {min}",
        Message541: "Edit Scoring",
        Message542: "Only numbers can be entered for the score",
        Message543: "Add Scoring",
        Message544: "Scoring Options",
        Message545: "Scoring title cannot be empty",
        Message546: "The option content cannot be empty",
        Message547: "Counting type",
        Message548: "Counting method",
        Message549: "Sweep voting",
        Message550: "The required options can be added manually,",
        Message551: "The voting title is required, and the number of options is more than two",
        Message552: "Edit Voting",
        Message553: "Voting option",
        Message554: "There are no less than two voting options and the title is required",
        Message555: "Add Voting",
        Message556: "Vote",
        Message557: "Score",
        Message558: "Export Results",
        Message559: "",
        Message560: "Scores",
        Message561: "Scoring Item",
        Message562: "Total score",
        Message563: "Average score",
        Message564: "The highest score",
        Message565: "Lowest mark",
        Message566: "Number of graders",
        Message567: "Average Value",
        Message568: "Truncated Mean",
        Message569: "Total Participants:",
        Message570: "Truncated Mean",
        Message571: "Truncated Mean",
        Message572: "Voting result",
        Message573: "The current voting is anonymous and cannot be viewed",
        Message574: "The voting named ",
        Message575: "",
        Message576: " is anonymous and cannot be exported",
        Message577: "Vote",
        Message578: "Voting name:",
        Message579: "Voting rules:",
        Message580: "Voting results:",
        Message581: "Voter name",
        Message582: "Voter's choice",
        Message583: "Untitled voting",
        Message584: "Return data",
        Message585: "Voting options:",
        Message586: "Number of voters:",
        Message587: "Option name:",
        Message588: "{0} people voted",
        Message589: "The user has no name information",
        Message590: "Please enter a meeting name",
        Message591: "Book",
        Message592: "Select date",
        Message593: "Please fill in the meeting room",
        Message594: "Select period",
        Message595: "Meeting type",
        Message596: "Ordinary meeting",
        Message597: "Video conference",
        Message598: "Presenter password",
        Message599: "Data download",
        Message600: "After the data download is enabled, ordinary users will be allowed to download the meeting data after the meeting",
        Message601: "Data encryption",
        Message602: "After the encryption is enabled, the encrypted meeting data can only be opened and viewed with the application terminal",
        Message603: " ",
        Message604: "Please fill in the meeting name",
        Message605: "Meeting name length is limited to {msg}",
        Message606: "Please check whether the password is valid",
        Message607: "Here",
        Message608: "Log out",
        Message609: "Canceling an account is an irreversible operation. After canceling the account, you will not be able to log in to use the account.",
        Message610: "Before canceling your account, please confirm:",
        Message611: "After the account is canceled, the meeting data reserved by the account will be cleared",
        Message612: "Data such as the historical meetings completed by the account belong to the enterprise and will not be cleared.",
        Message613: "The files contained in your account have been properly saved: including but not limited to meeting recordings, etc.",
        Message614: "Confirm",
        Message615: "Authentication",
        Message616: "For your account security, please verify your identity first. After canceling the account, you will log out directly",
        Message617: "Authentication method",
        Message618: "Account canceled successfully",
        Message619: "Click to set the time",
        Message620: "Date",
        Message621: "Guest Password",
        Message622: "Create Now",
        Message623: "Book",
        Message624: "The meeting name is empty, please enter a meeting name",
        Message625: "Please select a meeting start date",
        Message626: "Please select a meeting start time",
        Message627: "To set the meeting password, the password length must be 6 digits",
        Message628: "The password length must be 6 digits",
        Message629: "Meeting created successfully",
        Message630: "'s meeting",
        Message631: "Click OK to complete the reservation {msg} or click another time node to complete the reservation",
        Message632: "This time period has been reserved by {msg}; click another time node to complete the reservation",
        Message633: "There has been a meeting in this time period",
        Message634: "Click OK to complete the reservation",
        Message635: "Do you want to select this time period?",
        Message636: "Hour",
        Message637: "Attention",
        Message638: "After selecting the time period, if you do not click OK in the small prompt box, ",
        Message639: "the time selected will not take effect",
        Message640: "Reservation time can only be selected within the last seven days",
        Message641: "If multiple meeting rooms are reserved at the same time, only the last reserved meeting room will take effect",
        Message642: "Reservations can be made within one week from today only, otherwise the reservation will fail",
        Message643: "Booker",
        Message644: "Whether to record",
        Message645: "Name:",
        Message646: "Phone number:",
        Message647: "Email:",
        Message648: "No Email",
        Message649: "Account:",
        Message650: "Login password:",
        Message651: "Organization:",
        Message652: "No company",
        Message653: "Go to create organization",
        Message654: "Go to pending review page",
        Message655: "Bind mobile phone",
        Message656: "No department",
        Message657: "Position",
        Message658: "No position",
        Message659: "No admin information",
        Message660: "See",
        Message661: "Username cannot contain special characters",
        Message662: "Exit Organization Description",
        Message663: "Exiting a organization is an irreversible operation. After exiting the organization:",
        Message664: "All business-related data in your account will be deleted",
        Message665: "Your account will be restored to a separate individual user account.",
        Message666: "Successfully exited the organization",
        Message667: "Please agree to the Exit Organization Description first",
        Message668: "Position",
        Message669: "Role",
        Message670: "Host",
        Message671: "Participant",
        Message672: "Temporary staff",
        Message673: "Joining time",
        Message674: "By department",
        Message675: "User List",
        Message676: "Selected List",
        Message677: "Please select participants",
        Message678: "Added successfully",
        Message679: "Failed to add",
        Message680: "File Template",
        Message681: "Click to download the template",
        Message682: "File Import",
        Message683: "Click to import file",
        Message684: "Temporary Staff Import Template",
        Message685: "Please upload a file that meets the format",
        Message686: "Send meeting notice",
        Message687: "Add participants",
        Message688: "Add temporary staff",
        Message689: "Import temporary staff",
        Message690: "Export participant list",
        Message691: "Search for participant names",
        Message692: "Meeting Notice",
        Message693: "Edit temporary staff",
        Message694: "Do you want to delete the selected person",
        Message695: "Participant List",
        Message696: "SMS Template",
        Message697: "Template Content",
        Message698: "Select All",
        Message699: "Sent successfully",
        Message700: "Failed to send",
        Message701: "This phone number already exists",
        Message702: "This phone number already exists",
        Message703: "Export sign-in list",
        Message704: "Search phone number",
        Message705: "No data to export",
        Message706: "Meeting Description",
        Message707: "No description",
        Message708: "Sign-in Time",
        Message709: "Sign-in List",
        Message710: "to",
        Message711: "Do you want to delete the recording files?",
        Message712: "File information obtained successfully",
        Message713: "Program Type",
        Message714: "Upgrader",
        Message715: "Version NO",
        Message716: "Please enter the version number",
        Message717: "Release Notes",
        Message718: "Please fill in the version information",
        Message719: "Please fill in the updated information",
        Message720: "Version File",
        Message721: "Fail to edit",
        Message722: "Try to upload again",
        Message723: "Currently it only supports",
        Message724: "File Format",
        Message725: "Relevant client files or upgrader files can be uploaded, only {0} format is allowed, please make sure to upload the correct format",
        Message726: "Please upload the version file",
        Message727: "Only one file can be uploaded",
        Message728: "File deletion error",
        Message729: "Search keyword",
        Message730: "Type",
        Message731: "Update content description",
        Message732: "View upgrade content",
        Message733: "Enable",
        Message734: "Enabling",
        Message735: "Disable",
        Message736: "Do you want to delete this item?",
        Message737: "Do you want to delete these items?",
        Message738: "This action will disable version files",
        Message739: "Disabled successfully",
        Message740: "Enabled",
        Message741: "This operation will modify the version file status",
        Message742: "Modification canceled",
        Message743: "Phone number",
        Message744: "The password is limited to {0} letters, numbers or characters, and the default password is",
        Message745: "Search name",
        Message746: "Batch Approved",
        Message747: "Batch Rejected",
        Message748: "User Type",
        Message749: "Apply Time",
        Message750: "Rejected",
        Message751: "Approved",
        Message752: "Approve",
        Message753: "Reject",
        Message754: "Please select the item that needs to be agreed",
        Message755: "Please select the item that needs to be rejected",
        Message756: "Do you want to reject?",
        Message757: "Do you want to agree?",
        Message758: "Do you want to delete it?",
        Message759: "Reviewed",
        Message760: "Not reviewed",
        Message761: "There are no items to agree to",
        Message762: "Application approved",
        Message763: "There are no items to reject",
        Message764: "Application rejected",
        Message765: "Whether to overwrite the following phone numbers",
        Message766: "Overwrite",
        Message767: "User Import Template",
        Message768: "No.{msg} user name or phone number is incorrect, please check",
        Message769: "The data format may be wrong, please check the data format",
        Message770: "Duplicate phone number",
        Message771: "User import failed",
        Message772: "Overwritten successfully",
        Message773: "Share the organization invitation link and join the organization at the same time",
        Message774: "Invitation Link",
        Message775: "Copy Link",
        Message776: "Need an audit ?",
        Message777: "Review",
        Message778: "No review required",
        Message779: "New link",
        Message780: "Copied successfully",
        Message781: "Member review",
        Message782: "Add personnel",
        Message783: "Import personnel",
        Message784: "Invite",
        Message785: "Export personnel",
        Message786: "Data processing",
        Message787: "User status",
        Message788: "Remove",
        Message789: "Do you want to delete the user?",
        Message790: "The accounts of these users will be canceled and the meeting data reserved by the accounts will be cleared;",
        Message791: "The generated historical data belongs to the enterprise and will not be cleared",
        Message792: "Do you want to deactivate this user? After deactivation, the account will be unavailable",
        Message793: "Do you want to enable this user?",
        Message794: "Do you want to reset your password?",
        Message795: "Password will be reset to default password",
        Message796: "Edit user",
        Message797: "Import user",
        Message798: "Invite to organization",
        Message799: "The password has been reset to the default password, please notify the member in time",
        Message800: "The administrator cannot be deleted, it is automatically filtered for you",
        Message801: "Conference cloud disk",
        Message802: "Complete",
        Message803: "Up-Pass",
        Message804: "Make a mistake",
        Message805: 'In a video conference, the default chairman password is "1"',
        Message806: "Cancel file upload",
        Message807: "Program version",
        Message808: "Regular version",
        Message809: "Domestic version",
        Message810: "The file name cannot exceed {num} characters",
        Message811: "File size must not exceed {msg}",
        Message812: "A maximum of {num} files can be uploaded at a time",
        Message813: "Room",
        Message814: "Search for conference name/conference number",
        Message815: "Search name/conference room/equipment model",
        Message816: "Quick meeting",
        Message817: "Confidential meeting",
        Message818: "Organization",
        Message819: "Vote",
        Message820: "Satisfaction measurement",
        Message821: "Please enter the poll title",
        Message822: "Failed to obtain the file path. Procedure",
        Message823: "File source",
        Message824: "Search for meeting name/subscriber/meeting number",
        Message825: "Request timeout, maximum duration one hour",
        Message826: "Please enter the score title",
        Message827: "When there are fewer than 3 raters, the average statistical score is automatically used",
        Message828: "Approve",
        Message829: "Oppose",
        Message830: "Abstention",
        Message831: "Satisfaction",
        Message832: "Basic satisfaction",
        Message833: "Dissatisfied",
        Message834: "Share the organization link to invite colleagues to join the organization",
        Message835: "Whether the administrator needs to review the membership:",
        Message836: "Terminal serial number",
        Message837: "Quantity of equipment",
        Message838: "User account number",
        Message839: "Number of meeting rooms",
        Message840: "This video encoding format or bit rate does not support online play, please download and view",
        Message841: "After the file encryption function is enabled, the data in the conference cannot be saved to the local client or shared. After the conference, the data cannot be viewed on the platform and can only be decrypted and downloaded",
        Message842: "Add meeting room",
        Message843: "Please ",
        Message844: "Device Management",
        Message845: " ",
        Message846: " ",
        Message847: "minutes",
        Message848: " ",
        Message849: "Search for name/phone number",
        Message850: "Signal: open file encryption, the conference data cannot be saved to the local client, cannot be shared, after the conference data cannot be viewed on the platform, can only decrypt download",
        Message851: "Encrypted (conference data can only be opened on ihub terminal)",
        Message852: "Signal: open data download, after the meeting data can be downloaded",
        Message853: "Organization ID:",
        Message854: "The address contains a maximum of 50 characters",
        Message855: "If the modification fails, try uploading again",
        Message856: "Currently, only the zip file format is supported",
        Message857: "Dissolving units is an unrecoverable operation. After the dissolution of the organization, all members become independent individual user accounts.",
        Message858: "This action overwrites the original agenda file. Do you want to continue? ",
        Message859: "Name/Module name/operation type",
        Message860: "This operation will disable the version file. Do you want to continue?",
        Message861: "This action will change the version file state. Do you want to continue? ",
        Message862: "People vote",
        Message863: "Search for phone number/name ",
        Message864: "The password is 8 to 16 alphanumeric characters. The default password is 12345678",
        Message865: "Search for participant name/phone number",
        Message866: "Relevant client files or upgrader files can be uploaded in zip format only, please be sure to upload the correct format",
        Message867: "Click OK to complete the reservation or click another time node to complete the reservation ",
        Message868: ", or click another time node to complete the appointment",
        Message869: "The video encoding format or bit rate does not support online playback, please download and view ",
        Message870: "Click to set Date ",
        Message871: "Click to set Room ",
        Message872: "Advanced Search",
        Message873: "Option Content",
        Message874: "user data",
        Message875: "option list",
        Message876: "Copy",
        Message877: "Copy Success",
        Message878: "Please input your email",
        Message879: "Please input your phone/email",
        Message880: "This email address has already been registered, you can log in directly",
        Message881: "Mobile phone number/email cannot be empty",
        Message882: "Register mobile phone",
        Message883: "Register E-mail",
        Message884: "Verification code has been sent to email",
    },
    add: {
        mes1: "The current meeting is",
        mes2: "Book",
        meetingDetails: "Meeting details",
    },
};